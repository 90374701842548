:root{
    --primary-color: #1b73e8;
    --primary-rgb-color: 31, 38, 80;
    --background-color: #222c61;
    --background-rgb-color: 34, 44, 97;
    --secondary-color: #cc9904;
    --secondary-rgb-color: 48, 55, 107;
    --info-color: #3a99d7;
    --info-rgb-color: 58, 153, 215;
    --success-color: #297c11;
    --success-rgb-color: 41, 124, 17;
    --ligth-green-color: #54fb78;
    --green-color-cie: #5fc990;
    --ligth-green-rgb-color: 84, 251, 120;
    --rouge-color: #ff0036;
    --rouge-rgb-color: 255, 0, 54;
    --danger-color: #e6525f;
    --danger-rgb-color: 230, 82, 95;
    --orange-110-color: #d06e0b;
    --orange-110-rgb-color: 208, 110, 11;
    --orange-color: #f17e0b;
    --yellow-color: #f6d414;
    --orange-rgb-color: 241, 126, 11;
    --warning-400-color: #ffd9a9;
    --warning-400-rgb-color: 255, 217, 169;
    --peace-color: #ffffff;
    --peace-rgb-color:255, 255, 255;
    --grey-color: #babec9;
    --medium-color: #797c83;
    --grey-rgb-color: 186, 190, 201;
    --dark-color: #000000;
    --dark-rgb-color: 0, 0, 0;

    --danger-color-light:#f5c2c7;
    --info-color-light:#fceacf;
    --green-color-light: #5fc990;
    --green-color-tint: #74c7c4;
}
