.custom_btn_primary {
  // background-color: #0665aa !important;
  // // border-radius: 30px !important;
  // color: #ffffff !important;
  // border: 1px solid #0665aa;
  // font-size: 12px !important;

  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  border: 1px solid var(--primary-color);
  font-size: 12px !important;

  &:hover {
    background-color: #ffffff !important;
    color: #226ab5 !important;
    border: 1px solid #226ab5;
  }
}

.annule {
  background-color: rgb(246, 120, 120) !important  ;
  color: #fff !important;
}

.termine {
  background-color: #78c078 !important;
  color: #fff !important;
}

.isValide {
  background-color: orange !important;
  color: #fff !important;
}

.badge {
  background-color: rgb(183, 183, 183);
  color: rgb(58, 57, 57);
}

.paginator-per-page {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.img-details {
  width: 25px !important;
  height: 25px !important;
}

.span-true-css {
  background-color: rgb(117, 191, 117) !important;
  font-size: 11px !important;
  color: #fff;
}

.span-false-css {
  background-color: #737676 !important;
  font-size: 11px !important;
  color: #fff;
}

.isReject {
  td {
    color: rgb(215, 111, 111) !important;
  }
}

.txt-align-center-width-60 {
  width: 60px;
  text-align: center;
}

.custom_btn_accueil_dialyse {
  background-color: #fff !important;
  // border-radius: 30px !important;
  color: #494a4a !important;
  border: 1px solid #494a4a;
  font-size: 12px !important;

  &:hover {
    background-color: #ffffff !important;
    color: #0665aa !important;
    border: 1px solid #0665aa;
  }
}

.custom_btn_secondary {
  background-color: #fff !important;
  // border-radius: 30px !important;
  color: #494a4a !important;
  border: 1px solid #494a4a;
  font-size: 12px !important;
  margin-right: 5px;

  &:hover {
    background-color: #ffffff !important;
    color: #0665aa !important;
    border: 1px solid #0665aa;
  }
}

.custom_btn_success {
  background-color: #3dbe3b !important;
  // border-radius: 30px !important;
  color: #ffffff !important;
  border: 1px solid #3dbe3b;
  font-size: 12px !important;

  &:hover {
    background-color: #ffffff !important;
    color: #3dbe3b !important;
    border: 1px solid #3dbe3b;
  }
}

.txt-align-center-width-40 {
  text-align: center;
  width: 40px;
}

.required_champs {
  color: red;
}

.settingSidebar {
  display: none;
}

.custom-btn-update {
  color: #87c587;
}

.custom-btn-delete {
  color: #fa7575;
}

.custom-td-action {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .custom-btn-lock {
    color: orange;
  }
}

.body {
  padding-top: 0px !important;
}

.custom-input {
  // border-radius: 30px;

  &:focus {
    border: 1px solid #b7b7b4;
    box-shadow: none !important;
  }
}

tr > td {
  font-size: 12px !important;
  padding: 5px;
}

.txt-align-center {
  text-align: center;
}

.btn-cancel {
  background-color: #86888a !important;
  // border-radius: 30px !important;
  color: #ffffff !important;
  border: 1px solid #7d8080;
  font-size: 12px !important;

  &:hover {
    background-color: #ffffff !important;
    color: #242525 !important;
  }
}

.modal-footer-custom {
  border-top: 1px solid #eceeee !important;
  padding: 0px;
}

.modal .modal-header {
  background-color: var(--primary-color); // #0665aa !important;
  padding: 10px !important;
  color: #fff;

  h4 {
    margin-bottom: 0px !important; 
  }
}

.modal-content {
  padding: 0px !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  background-color: #bdd9fb !important;
}

.mat-expansion-panel-body {
  border: 1px solid #eceeee;
}

tfoot {
  border: none !important;

  td {
    border-bottom: 0px !important;

    .span_total_entree {
      color: #0665aa;
      font-weight: bold;
    }

    .pagination-part {
      display: flex;
      align-items: center;
    }
  }
}

.span_total_entree {
  color: #0665aa;
  font-weight: bold;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  display: none !important;
}

.swal2-container.swal2-center > .swal2-popup {
  width: 370px;
  font-size: 12px;
}

.custom-table-head {
  th {
    a {
      cursor: pointer;
    }
  }
}

.body {
  &.table-responsive {
    padding: 10px !important;
  }
}

.isInactif {
  color: rgb(247, 107, 107);
}

label {
  font-weight: bold !important;
}

.custom-table-head {
  th {
    // background-color: #d2e1f2 !important;
    background-color: #717171 !important;
    color: #d9d8d8 !important;
    height: 35px !important;

    &.txt-align-center-counter-row {
      text-align: center;
      width: 70px;
    }
  }
}

.custom-btn-details {
  color: #858585;
  cursor: pointer;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

.custom-fieldset {
  border: 2px solid #eceeee;
  border-radius: 5px;
  padding: 8px;

  legend {
    font-size: 14px;
    color: #fb5a5a;
    font-weight: bold;
  }
}

.theme-white .sidebar .menu .list li {
  cursor: pointer;

  // &.active {
  //   border: 1px solid #c6c8cb;
  //   border-radius: 10px;
  // }
}

::ng-deep .mat-expansion-panel {
  background-color: #ededed !important;
}

::ng-deep .mat-expansion-panel-body {
  background-color: #fff;
  padding: 10px !important;
}

::ng-deep .cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

.custom-width-td-action {
  width: 130px !important;
}

.custom-input-app {
  padding-left: 10px !important;
}

.btn-custom-action-dropdown {
  background-color: #737676;
  color: #ffffff;
  font-size: 11px !important;
  border: 1px solid #737676;

  &:hover {
    background-color: #fff;
    color: #737676;
    border: 1px solid #737676;
  }
}

.dropdown-menu {
  left: -35px !important;
}

button {
  &.btn-consomation {
    font-size: 12px !important;
    background-color: #5dad64 !important;
    border: 1px solid #5dad64;
    color: #fff;
    &:hover {
      background-color: #fff !important;
      color: #5dad64 !important;
    }
  }

  &.custom_btn_cancel {
    font-size: 12px !important;
    background-color: #f74c6b !important;
    border: 1px solid #f74c6b;
    color: #fff;
    &:hover {
      background-color: #fff !important;
      color: #f6afb2 !important;
    }
  }

  &.btn-success {
    font-size: 12px !important;
    background-color: #5dad64 !important;
    border: 1px solid #5dad64;
    color: #fff;
    &:hover {
      background-color: #fff !important;
      color: #5dad64 !important;
    }
  }

  &.btn-print {
    font-size: 12px !important;
    &:hover {
      background-color: var(--primary-color) !important;
      color: #fff !important;
    }
  }
}

.numero-code {
  background-color: rgb(237, 180, 75);
  color: rgb(29, 28, 28);
  font-weight: bold;
  font-size: 10px !important;
}

.expired {
  background-color: rgb(231, 111, 111) !important;
}

.number-div {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  input {
    width: 92%;
    padding: 2px;
  }
  span {
    width: 8%;
    cursor: pointer;
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e8ecee !important;
}

.dropdown-validation-plainte {
  left: -92px !important;
  top: 30px !important;
}

.retour-effectue {
  background-color: orange;
  color: #fff;
  font-size: 10px !important;
  border-radius: 20px;
}

.retour-pas-encore-effectue {
  background-color: #5dad64;
  color: #fff;
  font-size: 10px !important;
  border-radius: 20px;
}

.btn-add-posologie {
  border: 1px solid #4e4f4f;
}

.illustration-selected-posologie {
  // width: 23px;
  height: 18px;
}

.customPosologiePopoverClass {
  width: 55vw !important;
  max-width: none !important;
  background: rgba(#222428, 0.8);

  .popover-header {
    background-color: rgba($color: #000000, $alpha: 0.65) !important;
    color: #fff;
  }

  .popover-arrow {
    display: none;
  }
}

.posologie-input {
  // font-size: 12px !important;
}

.posologie-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -4px;
  .btn-select {
    width: 10%;
  }

  .input-part-posologie {
    width: 90%;
  }
}

.theme-white .sidebar .menu .list .ml-menu {
  color: #e3e3e3 !important;
}

.theme-white .sidebar .menu .list li a {
  color: #e3e3e3 !important;
}


.custom-btn-save-manager {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-top: 15px;
}

.errorInputForm {
  border: 1px solid var(--danger-color);
}

.modal-width-65 {
  width: 65%;
}

fieldset {
  border: 1px solid var(--grey-color);
  padding: 5px;
  border-radius: 5px;

  legend {
      width: auto;
      margin-bottom: initial;
      font-weight: bold;
      font-size: 1rem;
      margin-top: -20px;
      background: #fff;
      padding: 0 10px;
      color: var(--primary-color);
  }
}